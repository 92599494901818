<template>
  <v-fade-transition mode="out-in">
    <v-col :md="formOpen ? 8 : 12" cols="12">
      <v-card class="pa-5" :min-height="computedHeight">
        <v-row dense>
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-row justify-md-start justify-center"
          >
            <span class="d-flex flex-row justify-start align-center">
              <h2>
                {{ $t("Realtime map") }}
              </h2>
              <v-tooltip top :max-width="300">
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    medium
                    v-on="on"
                    class="ml-6 color-gray-text rounded-0"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span v-if="!formOpen">
                  <!-- <ul> -->
                  <!-- <li> -->
                  {{
                    $t(
                      "In this view you can visualize the trips in course on the map"
                    )
                  }}
                  <!-- </li> -->
                  <!-- </ul> -->
                </span>
                <span v-if="formOpen">
                  <ul>
                    <li>
                      {{
                        $t(
                          "In this map view you can visualize the waypoints origin and destination"
                        )
                      }}
                    </li>
                    <li>
                      {{
                        $t(
                          "Also driver's availability near the origin point of the trip"
                        )
                      }}
                    </li>
                  </ul>
                </span>
              </v-tooltip>
              <span class="ml-4" v-if="!formOpen">
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="handleRefreshMap"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon medium>
                        {{ icons.mdiRefresh }}
                      </v-icon>
                    </v-btn>
                  </template>
                <span>
                  <!-- <ul> -->
                  <!-- <li> -->
                  {{
                    $t(
                      "By clicking here you can refresh the map"
                    )
                  }}
                  <!-- </li> -->
                  <!-- </ul> -->
                </span>
                </v-tooltip>
              </span>
            </span>
          </v-col>
          <v-col
            v-if="!formOpen"
            cols="12"
            md="6"
            class="d-flex justify-md-end justify-center"
          >
            <span class="">
              <v-btn
                class=""
                rounded
                color="success"
                @click.stop="handleClick()"
              >
                <span class="d-flex flex-row justify-center align-center">
                  <v-img :src="trip.trip" class="icon-white"></v-img>
                  <span class="ml-2">
                    {{ $t("New Trip") }}
                  </span>
                </span>
              </v-btn>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <GoogleMap
              :formOpen="formOpen"
              :refreshMap="refreshMap"
              :refreshTable="refreshTable"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-fade-transition>
</template>
<script>
import GoogleMap from "@/views/RunningTrips/components/Map/parts/GoogleMap.vue";

import { mdiInformationOutline, mdiRefresh } from "@mdi/js";
import trip from "@/assets/Trip_white.svg";
export default {
  components: {
    GoogleMap,
  },
  props: {
    formOpen: {
      type: Boolean,
      default: false,
    },
    refreshTable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiRefresh,
      },
      trip: { trip },
      refreshMap: false,
    };
  },
  methods: {
    handleClick() {
      this.$emit("emitClick");
    },
    handleRefreshMap() {
      this.refreshMap = !this.refreshMap;
      this.$emit("refreshMap");
    },
  },
  computed: {
    computedHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.formOpen ? "660.5px" : "500px";
      } else {
        return "400px";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.test2 {
  // position: relative;
}

.test3 {
  // z-index: 999;
  position: absolute;
  transform: translateX(125%);
  transform: translateX(370%);
}
.test4 {
  margin-right: 1900px;
}

.test {
  // position: absolute;
  // right: -5vw;
  // left: 0
  // transform: translateX(50%);
  // background: red;
}
.icon-white {
  color: #ffffff !important;
  max-width: 30px !important;
}
</style>
