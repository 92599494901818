export default
{
	"ERROR_CODE_EMAIL_ID_ALREADY_REGISTERED" : "401",
	"ERROR_CODE_SOCIAL_UNIQUEID_REQUIRED_OR_PASSWORD_SHOULD_BE_NULL" : "402",
	"ERROR_CODE_NOT_A_REGISTERED_USER" : "403",
	"ERROR_CODE_OLD_PASSWORD_MUST_NOT_BE_BLANK" : "404",
	"ERROR_CODE_FOR_ENTER_VALID_PAYMENT_TOKEN" : "405",
	"ERROR_CODE_NO_PROVIDER_FOUND_AROUND_YOU" : "406",
	"ERROR_CODE_NO_TRIP" : "407",
	"ERROR_CODE_TRIP_IS_ALREADY_CANCELLED" : "408",
	"ERROR_CODE_YOUR_TRIP_HISTORY_NOT_FOUND" : "409",
	"ERROR_CODE_YOUR_TRIP_DETAIL_NOT_FOUND" : "410",
	"ERROR_CODE_YOUR_TRIP_IS_NOT_END" : "411",
	"ERROR_CODE_YOU_HAVE_ALREADY_APPLY_REFERRAL_CODE" : "422",
	"ERROR_CODE_SORRY_YOU_CANNOT_SKIP_YOUR_REFERRAL_CODE" : "423",
	"ERROR_CODE_LOGOUT_NOT_SUCCESS=" : "424",
	"ERROR_CODE_NOT_GET_YOUR_DETAI" : "427",
	"ERROR_CODE_NO_PROVIDER_FOUND_SELECTED_SERVICE_TYPE_AROUND_YOU" : "428",
	"ERROR_CODE_NO_SERVICE_TYPE_FOUND" : "429",
	"ERROR_CODE_YOUR_PASSWORD_IS_NOT_MATCH_WITH_OLD_PASSWORD" : "433",
	"ERROR_CODE_ADD_CREDIT_CARD_FIRST" : "435",
	"ERROR_CODE_TRIP_NOT_FOUND" : "437",
	"ERROR_CODE_MIS_MATCH_FUTURE_TRIP_ID" : "438",
	"ERROR_CODE_NO_ANY_FUTURE_TRIP_OR_MISMATCH_ID" : "439",
	"ERROR_CODE_MIS_MATCH_CARD_ID" : "440",
	"ERROR_CODE_FOR_PORBLEM_IN_FETCHIN_CARD" : "441",
	"ERROR_CODE_CARD_NOT_FOUND" : "442",
	"ERROR_CODE_DESTINATION_NOT_SET" : "445",
	"ERROR_CODE_INVALID_PASSWORD" : "446",
	"ERROR_CODE_TRIP_IS_ALREADY_ACCEPTED": "449",
	"ERROR_CODE_INVALID_TOKEN" : "451",
	"ERROR_CODE_NO_SERVICE_TYPE_FOUND_IN_YOUR_CITY" : "421",
	"ERROR_CODE_COUNTRY_NOT_FOUND" : "452",
	"ERROR_CODE_MIS_MATCH_SCHEDULETRIP_ID" : "454",
	"ERROR_CODE_PHONE_NUMBER_ALREADY_USED" : "456",
	"ERROR_CODE_REFERRAL_CODE_INVALID" : "461",
	"ERROR_CODE_NO_INFORMATION_PAGE_FOUND" : "462",
	"ERROR_CODE_INVALID_PROMO_CODE" : "541",
	"ERROR_CODE_PROMOTIONAL_CODE_ALREADY_USED" : "542",
	"ERROR_CODE_PROMO_CODE_NOT_FOR_YOUR_AREA" : "543",
	"ERROR_CODE_PROMO_CODE_NOT_APPLY_ON_YOUR_PAYMENT_MODE" : "544",
	"ERROR_CODE_PROMO_CODE_EXPIRED_OR_INVALID" : "545",
	"ERROR_CODE_SOCIAL_UNIQUEID_REQUIRED_OR_PASSWORD_SHOULD_BE_NULL" : "412",
	"ERROR_CODE_NOT_A_REGISTERED_PROVIDER" : "413",
	"ERROR_CODE_OLD_PASSWORD_MUST_NOT_BE_BLANK" : "414",
	"ERROR_CODE_NO_TRIP_FOUND" : "415",
	"ERROR_CODE_NO_TRIP_FOUND_FOR_CANCEL" : "498",
	"ERROR_CODE_MISMATCH_PROVIDER_ID_OR_TRIP_ID" : "416",
	"ERROR_CODE_NOT_GET_TRIP_STATUS" : "417",
	"ERROR_CODE_YOUR_HISTORY_NOT_FOUND" : "418",
	"ERROR_CODE_YOUR_TRIP_DETAIL_NOT_FOUND" : "419",
	"ERROR_CODE_YOUR_TRIP_IS_NOT_END" : "420",
	"ERROR_CODE_NO_SERVICE_TYPE_FOUND_IN_YOUR_CITY" : "421",
	"ERROR_CODE_LOGOUT_FAILED" : "425",
	"ERROR_CODE_YOUR_PASSWORD_IS_NOT_MATCH_WITH_OLD_PASSWORD" : "434",
	"ERROR_CODE_YOUR_DOCUMENT_NOT_UPLOADED" : "443",
	"ERROR_CODE_YOUR_DOCUMENT_NOT_UPDATE" : "444",
	"ERROR_CODE_INVALID_PASSWORD" : "447",
	"ERROR_CODE_NO_TRIP" : "453",
	"ERROR_CODE_MIS_MATCH_PROVIDER_ID" : "457",
	"ERROR_CODE_NO_CITY_LIST_FOUND" : "458",
	"ERROR_CODE_FOR_ENTER_VALID_PAYMENT_TOKEN" : "459",
	"ERROR_CODE_FOR_PROBLEM_IN_ADD_CARD_PLEASE_RETRY" : "460",
	"ERROR_CODE_OUR_BUSINESS_NOT_IN_YOUR_COUNTRY" : "1001",
	"ERROR_CODE_OUR_BUSINESS_NOT_IN_YOUR_CITY" : "1002",
	"ERROR_CODE_YOUR_TRIP_PAYMENT_IS_PENDING" : "464",
	"ERROR_CODE_INVALID_EMERGENCY_CONTACT_ID" : "465",
	"ERROR_CODE_NO_COUNTRY_FOUND" : "466",
	"ERROR_CODE_NO_EMERGENCY_CONTACT_FOUND" : "467",
	"ERROR_CODE_ADD_EMERGENCY_CONTACT_FAILED" : "471",
	"ERROR_CODE_UPDATE_EMERGENCY_CONTACT_FAILED" : "472",
	"ERROR_CODE_EMERGENCY_CONTACT_DELETE_FAILED" : "473",
	"ERROR_CODE_USER_DETAIL_NOT_FOUND" : "474",
	"ERROR_CODE_DELETE_CARD_FAILED" : "475",
	"ERROR_CODE_DESELECT_CARD_FAILED" : "476",
	"ERROR_CODE_SELECT_CARD_FAILED" : "477",
	"ERROR_CODE_USER_DOCUMENT_LIST_NOT_FOUND" : "478",
	"ERROR_CODE_PROVIDER_DETAIL_NOT_FOUND" : "479",
	"ERROR_CODE_PROVIDER_DOCUMENT_LIST_NOT_FOUND" : "480",
	"ERROR_CODE_TRIP_SERVICE_NOT_FOUND" : "481",
	"ERROR_CODE_DOCUMENT_NOT_FOUND" : "482",
	"ERROR_CODE_DETAIL_NOT_FOUND" : "483",
	"ERROR_CODE_PROBLEM_IN_UPDATE_USER_PROFILE" : "484",
	"ERROR_CODE_PAY_PAYMENT_FAILED" : "485",
	"ERROR_CODE_RESPOND_TRIP_FAILED" : "486",
	"ERROR_CODE_PROBLEM_IN_APPLY_REFERRAL" : "487",
	"ERROR_CODE_PROBLEM_IN_UPDATE_PROVIDER_PROFILE" : "488",
	"ERROR_CODE_CHANGE_PROVIDER_STATUS_FAILED" : "489",
	"ERROR_CODE_UPDATE_LOCATION_FAILED" : "490",
	"ERROR_CODE_CANCEL_TRIP_FAILED" : "491",
	"ERROR_CODE_PROBLEM_PROVIDER_RATING_FAILED" : "492",
	"ERROR_CODE_SET_TRIP_STATUS_FAILED" : "493",
	"ERROR_CODE_SET_DESTINATION_FAILED" : "494",
	"ERROR_CODE_PROBLEM_IN_GET_NEAR_BY_PROVIDER" : "495",
	"ERROR_CODE_PROBLEM_IN_GET_FARE_ESTIMATE" : "496",
	"ERROR_CODE_EMERGENCY_CONTACT_ALREADY_ADDED" : "497",
	"ERROR_CODE_EMERGENCY_CONTACT_LIMIT_OVER" : "468",
	"ERROR_CODE_PROVIDER_DAILY_DETAIL_NOT_FOUND" : "469",
	"ERROR_CODE_YOU_ARE_REGISTER_WITH_THE_DISPATCHER" : "470",
	"ERROR_CODE_FOR_PROBLEM_IN_ADD_BANK_DETAIL_PLEASE_RETRY" : "601",
	"ERROR_CODE_FOR_PROBLEM_IN_UPDATE_BANK_DETAIL_PLEASE_RETRY" : "602",
	"ERROR_CODE_FOR_PROBLEM_IN_GET_BANK_DETAIL" : "603",
	"ERROR_CODE_PROBLEM_IN_ADD_WALLET" : "701",
	"ERROR_CODE_PROBLEM_IN_CHANGE_WALLET_STATUS" : "702",
	"ERROR_CODE_NOT_REGISTERED_OR_INVALID_EMAIL_ID" : "801",
	"ERROR_CODE_FOR_PROVIDER_NOT_GET_YOUR_DETAIL" : "426",
	"ERROR_CODE_FOR_PROBLEM_IN_UPDATE_DEVICE_TOKEN" : "802",
	"ERROR_CODE_NO_SCHEDULED_TRIP_FOUND" : "803",
	"ERROR_CODE_EMAIL_ID_ALREADY_REGISTERED" : "401",
	"ERROR_CODE_SOCIAL_UNIQUEID_REQUIRED_OR_PASSWORD_SHOULD_BE_NULL" : "402",
	"ERROR_CODE_NOT_A_REGISTERED_USER" : "403",
	"ERROR_CODE_OLD_PASSWORD_MUST_NOT_BE_BLANK" : "404",
	"ERROR_CODE_FOR_ENTER_VALID_PAYMENT_TOKEN" : "405",
	"ERROR_CODE_NO_PROVIDER_FOUND_AROUND_YOU" : "406",
	"ERROR_CODE_NO_TRIP" : "407",
	"ERROR_CODE_TRIP_IS_ALREADY_CANCELLED" : "408",
	"ERROR_CODE_YOUR_TRIP_HISTORY_NOT_FOUND" : "409",
	"ERROR_CODE_YOUR_TRIP_DETAIL_NOT_FOUND" : "410",
	"ERROR_CODE_YOUR_TRIP_IS_NOT_END" : "411",
	"ERROR_CODE_YOU_HAVE_ALLREADY_APPLY_REFERRAL_CODE" : "422",
	"ERROR_CODE_SORRY_YOU_CANNOT_SKIP_YOUR_REFERRAL_CODE" : "423",
	"ERROR_CODE_LOGOUT_NOT_SUCCESS" : "424",
	"ERROR_CODE_NOT_GET_YOUR_DETAIL" : "427",
	"ERROR_CODE_NO_PROVIDER_FOUND_SELECTED_SERVICE_TYPE_AROUND_YOU" : "428",
	"ERROR_CODE_NO_SERVICE_TYPE_FOUND" : "429",
	"ERROR_CODE_YOUR_PASSWORD_IS_NOT_MATCH_WITH_OLD_PASSWORD" : "433",
	"ERROR_CODE_ADD_CREDIT_CARD_FIRST" : "435",
	"ERROR_CODE_TRIP_NOT_FOUND" : "437",
	"ERROR_CODE_MIS_MATCH_FUTURE_TRIP_ID" : "438",
	"ERROR_CODE_NO_ANY_FUTURE_TRIP_OR_MISMATCH_ID" : "439",
	"ERROR_CODE_MIS_MATCH_CARD_ID" : "440",
	"ERROR_CODE_CARD_NOT_FOUND" : "441",
	"ERROR_CODE_CARD_NOT_FOUND" : "442",
	"ERROR_CODE_DESTINATION_NOT_SET" : "445",
	"ERROR_CODE_INVALID_PASSWORD" : "446",
	"ERROR_CODE_INVALID_TOKEN" : "451",
	"ERROR_CODE_NO_SERVICE_TYPE_FOUND_IN_YOUR_CITY" : "421",
	"ERROR_CODE_COUNTRY_NOT_FOUND" : "452",
	"ERROR_CODE_MIS_MATCH_SCHEDULETRIP_ID" : "454",
	"ERROR_CODE_PHONE_NUMBER_ALREADY_USED" : "456",
	"ERROR_CODE_REFERRAL_CODE_INVALID" : "461",
	"ERROR_CODE_NO_INFORMATION_PAGE_FOUND" : "462",
	"ERROR_CODE_REFERRAL_CODE_EXPIRED" : "898",
	"ERROR_CODE_INVALID_PROMO_CODE" : "541",
	"ERROR_CODE_PROMOTIONAL_CODE_ALREADY_USED" : "542",
	"ERROR_CODE_PROMO_CODE_NOT_FOR_YOUR_AREA" : "543",
	"ERROR_CODE_PROMO_CODE_NOT_APPLY_ON_YOUR_PAYMENT_MODE" : "544",
	"ERROR_CODE_PROMO_CODE_EXPIRED_OR_INVALID" : "545",
	"ERROR_CODE_SOCIAL_UNIQUEID_REQUIRED_OR_PASSWORD_SHOULD_BE_NULL" : "412",
	"ERROR_CODE_NOT_A_REGISTERED_PROVIDER" : "413",
	"ERROR_CODE_OLD_PASSWORD_MUST_NOT_BE_BLANK" : "414",
	"ERROR_CODE_NO_TRIP_FOUND" : "415",
	"ERROR_CODE_MISMATCH_PROVIDER_ID_OR_TRIP_ID" : "416",
	"ERROR_CODE_NOT_GET_TRIP_STATUS" : "417",
	"ERROR_CODE_YOUR_HISTORY_NOT_FOUND" : "418",
	"ERROR_CODE_YOUR_TRIP_DETAIL_NOT_FOUND" : "419",
	"ERROR_CODE_YOUR_TRIP_IS_NOT_END" : "420",
	"ERROR_CODE_NO_SERVICE_TYPE_FOUND_IN_YOUR_CITY" : "421",
	"ERROR_CODE_LOGOUT_FAILED" : "425",
	"ERROR_CODE_YOUR_PASSWORD_IS_NOT_MATCH_WITH_OLD_PASSWORD" : "434",
	"ERROR_CODE_YOUR_DOCUMENT_NOT_UPLOADED" : "443",
	"ERROR_CODE_YOUR_DOCUMENT_NOT_UPDATE" : "444",
	"ERROR_CODE_INVALID_PASSWORD" : "447",
	"ERROR_CODE_YOU_ARE_NOT_REGISTERED_WITH_THIS_SOCIAL": "448",
	"ERROR_CODE_NO_TRIP" : "453",
	"ERROR_CODE_MIS_MATCH_PROVIDER_ID" : "457",
	"ERROR_CODE_NO_CITY_LIST_FOUND" : "458",
	"ERROR_CODE_FOR_ENTER_VALID_PAYMENT_TOKEN" : "459",
	"ERROR_CODE_FOR_PORBLEM_IN_ADD_CARD_PLEASE_RETRY" : "460",
	"ERROR_CODE_OUR_BUSINESS_NOT_IN_YOUR_COUNTRY" : "1001",
	"ERROR_CODE_OUR_BUSINESS_NOT_IN_YOUR_CITY" : "1002",
	"ERROR_CODE_OUR_BUSINESS_NOT_IN_YOUR_AREA": "1003",
	"ERROR_CODE_YOUR_TRIP_PAYMENT_IS_PENDING" : "464",
	"ERROR_CODE_INVALID_EMERGENCY_CONTACT_ID" : "465",
	"ERROR_CODE_NO_COUNTRY_FOUND" : "466",
	"ERROR_CODE_NO_EMERGENCY_CONTACT_FOUND" : "467",
	"ERROR_CODE_EMERGENCY_CONTACT_LIMIT_OVER" : "468",
	"ERROR_CODE_PROVIDER_DAILY_DETAIL_NOT_FOUND" : "469",
	"ERROR_CODE_YOU_ARE_REGISTER_WITH_THE_DISPATCHER" : "470",
	"ERROR_CODE_FOR_PORBLEM_IN_ADD_BANK_DETAIL_PLEASE_RETRY" : "601",
	"ERROR_CODE_FOR_PORBLEM_IN_UPDATE_BANK_DETAIL_PLEASE_RETRY" : "602",
	"ERROR_CODE_FOR_PORBLEM_IN_GET_BANK_DETAIL" : "603",
	"ERROR_CODE_PROBLEM_IN_ADD_WALLET" : "701",
	"ERROR_CODE_PROBLEM_IN_CHANGE_WALLET_STATUS" : "702",
	"ERROR_CODE_NOT_REGISTERED_OR_INVALID_EMAIL_ID" : "801",
	"ERROR_CODE_FOR_PROVIDER_NOT_GET_YOUR_DETAIL" : "426",
	"ERROR_CODE_FOR_PROBLEM_IN_UPDATE_DEVICE_TOKEN" : "802",
	"ERROR_CODE_NO_SCHEDULED_TRIP_FOUND" : "803",
	"ERROR_CODE_FOR_USER_PROBLEM_IN_UPDATE_DEVICE_TOKEN" : "804",
	"ERROR_CODE_STRIP_CHARGES_FAILED" : "703",
	"ERROR_CODE_APP_KEYS_NOT_FOUND" : "1101",
	"ERROR_CODE_SETTING_DETAIL_NOT_FOUND" : "1102",
	"ERROR_CODE_YOUR_FRIEND_COUNTRY_NOT_MATCH_WITH_YOU" : "897",
	"ERROR_CODE_USER_NOT_APPROVED" : "899",
	"ERROR_CODE_SET_ADDRESS_FAILED" : "901",
	"ERROR_CODE_EARNING_NOT_FOUND" : "805",
	"ERROR_CODE_NOT_REGISTERED" : "806",
	"ERROR_CODE_TRIP_CANCELLED_BY_USER" : "807",
	"ERROR_CODE_EMAIL_ID_ALREADY_REGISTERED_WITH_SOCIAL" : "808",
	"ERROR_CODE_WALLET_HISTORY_NOT_FOUND" : "809",
	"ERROR_CODE_CITY_TYPE_NOT_FOUND" : "810",
	"ERROR_CODE_LANGUAGES_NOT_FOUND" : "811",
	"ERROR_CODE_CREATE_TRIP_FAILED" : "812",
	"ERROR_CODE_TRIP_ALREADY_COMPLETED" : "813",
	"ERROR_CODE_COMPLETE_TRIP_FAILED" : "814",
	"ERROR_CODE_USER_CHECK_FAILED" : "815",
	"ERROR_CODE_SOMETHING_WENT_WRONG" : "991",
	"ERROR_CODE_PARAMETER_MISSING" : "992",
	"ERROR_CODE_PARAMETER_INVALID" : "993",
	"ERROR_CODE_TRIP_CANCELLED_BY_PROVIDER" : "994",
	"ERROR_CODE_TRIP_ALREADY_RUNNING": "995",
	"ERROR_CODE_USER_IS_NOT_REGISTERED": "996",
	"ERROR_CODE_CORPORATE_WALLET_NOT_ENOUGH" : "997",
	"ERROR_CODE_YOUR_TRIP_IS_NOT_END_DUE_TO_PAYMENT" : "500",
	"ERROR_CODE_WALLET_TRANSACTION_IS_ALREADY_USED" : "704",
	"ERROR_CODE_WALLET_TRANSACTION_NOT_FOUND" : "705",
	"ERROR_CODE_WALLET_TRANSACTION_RETRY" : "706",
	"ERROR_CODE_WALLET_TRANSACTION_AMOUNT_NOT_ENOUGH" : "707",
	"ERROR_CODE_SOS_ALREADY_CREATED" : "998"
}