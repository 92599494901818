<template>
  <div>
    <!-- Component used only for rendering the view to download a quote -->
    <QuoteInvoice
      ref="invoiceComponent"
      style="display: none; position: absolute; right: 10000px"
      :quote="quote"
    />
    <Map
      @tripCreated="tripCreated"
      @refreshMap="handleRefreshMap()"
      :refreshTable="refreshTable"
      @downloadQuotePDF="downloadInvoice()"
      @updateQuote="updateQuote($event)"
    />
    <br />
    <Table
      :trip="trip"
      :refreshMap="refreshMap"
      @refreshTable="handleRefreshTable()"
    />
  </div>
</template>

<script>
import Map from "./components/Map/Map.vue";
import Table from "./components/Table/Table.vue";
import { mapActions, mapState } from "vuex";
import QuoteInvoice from "./components/Invoice/QuoteInvoice.vue";
import html2pdf from "html2pdf.js";
import axios from "@/plugins/axios";

export default {
  components: {
    Map,
    Table,
    QuoteInvoice,
  },
  data() {
    return {
      trip: false,
      refreshMap: false,
      refreshTable: false,
      quote: {},
    };
  },
  methods: {
    ...mapActions("auth", ["me", "meNotLoading"]),
    ...mapActions(["setLoading"]),
    tripCreated() {
      this.trip = !this.trip;
      this.meNotLoading();
    },
    handleRefreshMap() {
      this.refreshMap = !this.refreshMap;
      this.meNotLoading();
    },
    handleRefreshTable() {
      this.refreshTable = !this.refreshTable;
      this.meNotLoading();
    },
    updateQuote(quote) {
      this.quote = quote;
    },
    downloadInvoice() {
      const isDarkMode = this.$vuetify.theme.dark;
      const invoiceComponent = this.$refs.invoiceComponent;
      this.setLoading(true);
      try {
        if (isDarkMode) {
          this.$vuetify.theme.dark = false;
        }

        setTimeout(async () => {
          const element = invoiceComponent.$refs.carInvoice;
          element.className = "col-12 fontUrbanist";
          const images = element.querySelectorAll("img");
          for (const img of images) {
            if (!img.src.startsWith("https://riderys3bucket.s3")) {
              continue;
            }
            try {
              const response = await axios.get(
                `${process.env.VUE_APP_STAGING}/corporate/proxy?url=${img.src}`,
                {
                  responseType: "blob",
                }
              );
              const blob = response.data;
              const reader = new FileReader();
              reader.onloadend = () => {
                img.src = reader.result;
              };
              reader.readAsDataURL(blob);
            } catch (error) {
              console.error("Error fetching image:", error);
            }
          }

          // Wait for all images to be converted to base64
          await new Promise((resolve) => setTimeout(resolve, 1000));

          const opt = {
            margin: 5,
            filename: `Ridery Corporate Panel - ${this.$t(
              "Service Quote"
            )}.pdf`,
            image: { type: "jpeg", quality: 0.95 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };

          invoiceComponent.$el.style.display = "block";
          html2pdf()
            .set(opt)
            .from(element)
            .save()
            .finally(() => {
              this.setLoading(false);
              if (isDarkMode) {
                this.$vuetify.theme.dark = true;
              }
            });
        }, 10);
      } catch (error) {
        this.setLoading(false);
        if (isDarkMode) {
          this.$vuetify.theme.dark = true;
        }
      } finally {
        invoiceComponent.$el.style.display = "none";
      }
    },
  },
  computed: {
    ...mapState(["stateDriverCancelledEvent"]),
  },
  watch: {
    stateDriverCancelledEvent: function () {
      this.handleRefreshMap();
      this.handleRefreshTable();
    },
  },
  async created() {
    await this.me();
  },
};
</script>
